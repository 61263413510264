import React, { FC, useEffect, useCallback } from "react";
import {
  DialogContent,
  Typography,
  FormControl,
  Button,
  Grid
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import useState from "@RHCommerceDev/hooks/useState";
import { querySessionConf } from "@RHCommerceDev/graphql-client/queries/session-conf";
import analyticsLoader from "@RHCommerceDev/analytics/loader";
import { queryGalleries } from "@RHCommerceDev/graphql-client/queries/galleries";
import {
  BlurTextValidator,
  BlurSelectValidator
} from "@RHCommerceDev/component-input-validation";
import { ValidatorForm } from "react-material-ui-form-validator";
import useFormValidators from "@RHCommerceDev/hooks/useFormValidators";
import FormDialog from "@RHCommerceDev/layout-form-dialog";
import { mutationSendScheduleAppointmentEmail } from "@RHCommerceDev/graphql-client/queries/send-schedule-appointment-email";
import { queryReturnContentContentWindowFragment } from "@RHCommerceDev/graphql-client/queries/content";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import clsx from "clsx";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";

export interface CustomWindowDialogContentProps {
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  profile?: Profile;
  galleries: Gallery[];
  customWindow: {
    header?: string;
    paragraph?: string;
    placeholder?: string;
    subheader?: string;
    successmessage?: string;
    successtitle?: string;
  };
  onRequest: (input: ScheduleAppointmentInput) => Promise<string>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const CustomWindowDialogContent: FC<CustomWindowDialogContentProps> = ({
  onClose,
  galleries = [],
  customWindow = {},
  onRequest,
  loading,
  error
}) => {
  const rhUser = useRhUserAtomValue();
  const isGuestUser =
    rhUser?.userType === "ANONYMOUS" || rhUser?.userType === "GUEST";
  const { pageContent } = useFetchModel(`admin/custom-windows`, false, false);

  const [state, , dispatch] = useState({
    firstName: isGuestUser || !rhUser?.firstName ? "" : rhUser?.firstName,
    lastName: isGuestUser || !rhUser?.lastName ? "" : rhUser?.lastName,
    customerEmail: isGuestUser || !rhUser?.email ? "" : rhUser?.email,
    customerPhone: "",
    galleryContact: "",
    preferredContact: "EMAIL",
    message: "",
    requestType: "Custom Window"
  });

  useEffect(() => {
    analyticsLoader(a =>
      a.emitAnalyticsEvent(
        // @ts-ignore
        document.querySelector("#spa-root > *")!,
        a.EVENTS.REQ_CONSULTATION_CLICK.INT_TYPE
      )
    );
  }, []);

  useFormValidators();

  const [statusCode, setStatusCode] = useState<string>("");
  const [isLocationValid, setLocationValid] = useState<boolean>(true);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH1", "rhBaseBody1", "uppercaseText"]
  });
  const onSubmit = useCallback(async () => {
    const { firstName, lastName, ...input } = state;
    const statusCode = await onRequest({
      ...input,
      customerName: `${firstName} ${lastName}`
    });
    setStatusCode(statusCode);
  }, [state, onRequest]);

  const handleBlurValidate = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (
        event.target.name === "gallery-select" &&
        event.target.value === "unchosen"
      ) {
        setLocationValid(false);
      } else {
        setLocationValid(true);
      }
    },
    []
  );

  if (statusCode === "200") {
    return (
      <DialogContent>
        <Typography
          className={typographyStyles.rhBaseH1}
          dangerouslySetInnerHTML={{
            __html: customWindow ? customWindow.header : ""
          }}
          paragraph
        />
        <Typography
          className={`[&>p]:mb-[8px] sm:[&>p]:mb-[13px] !mt-[24px] !pl-[3.2px]`}
          style={{
            textTransform: "initial"
          }}
          dangerouslySetInnerHTML={{
            __html: customWindow?.successmessage ?? ""
          }}
          paragraph
        />
        <Grid item container xs={12} sm={6}>
          <FormControl fullWidth>
            <Button
              className={`sm:!mt-[24px] sm:!mb-[160px] !text-white !bg-black hover:!bg-[#404040] hover:!border-[#404040]`}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={e => onClose?.(e, "backdropClick")}
            >
              {pageContent?.EXPLORE_RH}
            </Button>
          </FormControl>
        </Grid>
      </DialogContent>
    );
  }

  return (
    <DialogContent style={{ overflow: "hidden" }}>
      <Typography
        className={clsx([
          typographyStyles.rhBaseH1,
          typographyStyles.uppercaseText
        ])}
        dangerouslySetInnerHTML={{
          __html: customWindow ? customWindow.header : ""
        }}
        paragraph
      />
      <Typography
        className={"!text-[1.25rem] !uppercase"}
        dangerouslySetInnerHTML={{
          __html: customWindow?.subheader ?? ""
        }}
        paragraph
      />
      <Typography
        className={"!my-[30px]"}
        dangerouslySetInnerHTML={{
          __html: customWindow?.paragraph ?? ""
        }}
        paragraph
      />
      <ValidatorForm
        debounceTime={1000}
        instantValidate={false}
        onSubmit={onSubmit}
      >
        <Grid container spacing={2}>
          {loading && (
            <Grid item container justifyContent="center" alignItems="center">
              <RHSpinner
                data-testid="request-consultation-loading-spinner"
                style={{
                  zIndex: 1
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.FIRST_NAME}
              value={state.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("firstName")(event.target.value)
              }
              name="firstName"
              id="firstName"
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.FIRST_NAME_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.LAST_NAME}
              value={state.lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("lastName")(event.target.value)
              }
              name="lastName"
              id="lastName"
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.LAST_NAME_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.EMAIL}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("customerEmail")(event.target.value)
              }
              name="customerEmail"
              id="customerEmail"
              value={state.customerEmail}
              validators={["required", "isEmail"]}
              errorMessages={[
                pageContent?.EMAIL_REQ,
                pageContent?.NOT_VALID_EMAIL
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.PHONE}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("customerPhone")(event.target.value)
              }
              name="customerPhone"
              id="customerPhone"
              value={state.customerPhone}
              validators={["required", "isPhone"]}
              errorMessages={[
                pageContent?.PHONE_REQ,
                pageContent?.NOT_VALID_PHONE
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHTextField
              label={pageContent?.SELECT_NEAREST_GALLERY}
              value={
                state.galleryContact
                  ? state.galleryContact
                  : pageContent?.UNCHOSEN
              }
              style={{ marginTop: 0 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("galleryContact")(event.target.value)
              }
              onBlur={handleBlurValidate}
              fullWidth
              required
              select
              id="gallery-select"
              name="gallery-select"
              error={!isLocationValid}
              helperText={
                !isLocationValid ? pageContent?.NEAREST_LOCATION_REQ : ""
              }
            >
              <option key={"none"} value={"unchosen"}>
                {pageContent?.SELECT_LOCATION}
              </option>
              {galleries.map(gallery => {
                if (
                  gallery.serviceOfferings &&
                  gallery.serviceOfferings.offersInteriorDesign
                ) {
                  return (
                    <option
                      key={gallery.docId}
                      value={gallery.number?.toString()}
                    >
                      {gallery.address.state}, {gallery.address.city} -{" "}
                      {gallery.name}
                    </option>
                  );
                }
                return null;
              })}
            </RHTextField>
          </Grid>
          <Grid item container xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <BlurSelectValidator
                id="preferred-contact"
                name="preferred-contact"
                label={pageContent?.PREFERRED_METHOD_OF_CONTACT}
                fullWidth
                select
                variant="outlined"
                value={state.preferredContact}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch("preferredContact")(event.target.value)
                }
                inputProps={{
                  "data-testid": "preferred-contact"
                }}
                validators={["required"]}
                errorMessages={[pageContent?.PREFERRED_METHOD_OF_CONTACT_REQ]}
              >
                <option key={"EMAIL"} value={"EMAIL"}>
                  {pageContent?.EMAIL}
                </option>
                <option key={"TEXT"} value={"TEXT"}>
                  {pageContent?.TEXT}
                </option>
                <option key={"PHONE"} value={"PHONE"}>
                  {pageContent?.PHONE}
                </option>
                <option key={"VIDEO"} value={"VIDEO"}>
                  {pageContent?.VIDEO_CONFERENCE_CALL}
                </option>
              </BlurSelectValidator>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <BlurTextValidator
              fullWidth
              variant="outlined"
              label={pageContent?.WINDOW_PROJECT}
              placeholder={customWindow?.placeholder ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("message")(event.target.value)
              }
              InputLabelProps={{
                shrink: true
              }}
              multiline
              rows="5"
              name="message"
              id="message"
              value={state.message}
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.DESCRIPTION_REQ,
                pageContent?.MUST_BE_ATLEAST_TWO_CHAR
              ]}
            />
          </Grid>
          <Grid item container xs={12} sm={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || Object.values(state).some(value => !value)}
                fullWidth
                className={`!text-white !bg-black hover:!bg-[#404040] hover:!border-[#404040]`}
              >
                {pageContent?.REQUEST_A_DESIGN_CONSULTATION}
              </Button>
            </FormControl>
          </Grid>
          {error && (
            <Grid item container xs={12}>
              <Typography
                color="error"
                align="center"
                className={typographyStyles.rhBaseBody1}
              >
                {pageContent?.SOMETHING_WRONG}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    </DialogContent>
  );
};

CustomWindowDialogContent.defaultProps = {};

export type CustomWindowDialogProps = Omit<DialogProps, "children">;

export default ({ ...rest }: CustomWindowDialogProps) => {
  const rhUser = useRhUserAtomValue();
  const { data: { sessionConf } = {} as Query } = useQuery<Query>(
    querySessionConf,
    {
      fetchPolicy: "no-cache",
      skip: !rest.open
    }
  );

  const { data: { galleries } = {} as Query } = useQuery<Query>(
    queryGalleries,
    {
      skip: !rest.open
    }
  );

  const { data: { contentCustomWindowResponse } = {} as Query } =
    useQuery<Query>(queryReturnContentContentWindowFragment);

  const [sendScheduleAppointmentEmail, { loading, error }] =
    useMutation<Mutation>(mutationSendScheduleAppointmentEmail, {
      context: {
        fetchOptions: {
          method: "POST"
        }
      }
    });

  const onRequestCb = useCallback(
    async input => {
      if (sessionConf) {
        const { data } = await sendScheduleAppointmentEmail({
          variables: {
            input,
            sessionConfirmation: sessionConf.sessionConfirmation
          }
        });

        if (data) {
          return data.sendScheduleAppointmentEmail.statusCode;
        }
      }

      return Promise.reject();
    },
    [sendScheduleAppointmentEmail, sessionConf]
  );

  return rhUser ? (
    <FormDialog {...rest} onClose={rest.onClose}>
      <CustomWindowDialogContent
        galleries={galleries}
        onClose={rest.onClose}
        customWindow={contentCustomWindowResponse}
        onRequest={onRequestCb}
        loading={loading}
        error={error}
      />
    </FormDialog>
  ) : null;
};
