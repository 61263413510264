import React, { FC, useCallback, useLayoutEffect } from "react";
import { Dialog, useTheme } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog";
import useState from "@RHCommerceDev/hooks/useState";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";
import RHDialogTitle from "@RHCommerceDev/component-rh-dialog-title";

export interface IframeDialogContentProps {
  src?: string;
  srcDoc?: string;
  iframeCSS?: string;
  parentBaseId?: string;
}

export const IframeDialogContent: FC<IframeDialogContentProps> = ({
  src,
  srcDoc,
  parentBaseId
}) => {
  const baseId = `${parentBaseId}-IFrame-dailog`;
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    setLoading(true);
  }, [src, srcDoc]);

  const shouldLoadAsHtml = useCallback(() => {
    return srcDoc?.includes("href");
  }, [srcDoc]);

  return (
    <>
      {shouldLoadAsHtml() ? (
        <div dangerouslySetInnerHTML={{ __html: srcDoc }} />
      ) : (
        <>
          <iframe
            title="rh"
            style={{
              flex: 1,
              width: "100%"
            }}
            src={src}
            srcDoc={srcDoc}
            frameBorder={0}
            allow="autoplay"
            allowFullScreen
            onLoad={() => setLoading(false)}
          />
          {loading && <RHSpinner color="white" />}
        </>
      )}
    </>
  );
};

IframeDialogContent.defaultProps = {};

export default ({
  src,
  srcDoc,
  iframeCSS,
  ...rest
}: IframeDialogContentProps & Omit<DialogProps, "children">) => {
  const theme = useTheme();
  if (srcDoc && iframeCSS) {
    srcDoc = `<style>${iframeCSS}</styles>${srcDoc}`;
  }
  return (
    <Dialog
      {...rest}
      PaperProps={{
        ...rest.PaperProps,
        style: {
          color: theme.palette.common.white,
          background: theme.palette.common.black,
          ...rest.PaperProps?.style
        }
      }}
      fullScreen
    >
      <RHDialogTitle
        onClose={rest.onClose}
        style={{
          color: "inherit",
          backgroundColor: "inherit"
        }}
        closeIconClass="text-white"
      />
      <IframeDialogContent src={src} srcDoc={srcDoc} {...rest} />
    </Dialog>
  );
};
